$(document).ready(function () {

    $('.init_archive_model').on('click', function () {
        let btn = $(this);

        let action = btn.data('archiveAction');
        let title = btn.data('archiveTitle');

        $('#title_archive_model p').text(title);
        $('#approve_btn_archive').data('archiveAction', action);
    });

    $('#approve_btn_archive').on('click', function () {
        let btn = $(this);

        $.ajax({
            type: "POST",
            url: btn.data('archiveAction'),
            dataType: 'json',
            success: function (response) {
                $('#tr_model_'+ response.archive_model_id).remove();

                $('#title_archive_model p').text('');
                $('#approve_btn_archive').data('archiveAction', '');

                $('#archive_modal').modal('hide');

                notifySuccess(response.message);
            },
            error: function (xhr, status, err) {
                notifyError();
            }
        });
    });

});
