$(document).ready(function () {

    $(document).on('click', '.smart_update', function () {

        let productId = $(this).data('product-id');
        let action = $(this).data('action');

        let trForm = $(`#tr_smart_update_form_${productId}`);

        if (trForm.hasClass('d-none')) {

            $.ajax({
                type: "POST",
                url: action,
                dataType: 'json',
                success: function (response) {
                    trForm.html(response.html);
                },
                complete: () => {
                    window.initEditor(productId);
                },
                error: function () {
                    notifyError();
                }
            });

            trForm.removeClass('d-none').addClass('d-block');
        } else {
            trForm.removeClass('d-block').addClass('d-none').html('');
        }
    });

    $(document).on('click', '.smart_form_submit', function (e) {
        e.preventDefault();

        let productId = $(this).data('productId');

        let productItemContainer = $(`#product_list_item_${productId}`);

        let trForm = $(`#tr_smart_update_form_${productId}`);

        let form = $(`#smart_update_product_${productId}`);

        $.ajax({
            type: "POST",
            url: form.data('action'),
            data: {
                ...$(form).serializeJSON(),
                smart_edit: true
            },
            dataType: 'json',
            beforeSend: function() {
                form.css({"opacity":"0.5"});
            },
            success: function (response) {

                productItemContainer.html(response.productItemHtml);
                trForm.html(response.formHtml);
                window.initEditor(productId);

                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        }).always(() => {
            form.css({"opacity":"1"});
        });
    })

});

function getFormData($form){
    const unindexed_array = $form.serializeArray();
    console.log(unindexed_array)
    let indexed_array = {};

    $.map(unindexed_array, function(n, i){
        console.log(n)
        indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
}
