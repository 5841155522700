$(document).ready(function () {

    $('#receipt_search_input').on('keyup', function(e) {

        e.preventDefault();

        if ($(this).val().length < 3) {
            $('#result_receipt_search').html('');
            return false;
        }

        let data = {
            search: $(this).val()
        };

        $.ajax({
            type: "POST",
            url: $(this).data('action'),
            data: data,
            dataType: 'json',
            success: function (response) {

                $('#result_receipt_search').html('');

                $.each(response.products, function (key, item) {
                    $('#result_receipt_search').prepend(item)
                });

            },
            error: function () {
                notifyError();
            }
        });
    });

    $(document).on('click', '.searched_product', function (e) {

        e.preventDefault();

        let data = {
            productId: $(this).data('productId')
        };

        $.ajax({
            type: "POST",
            url: $('#result_receipt_search').data('action'),
            data: data,
            dataType: 'json',
            success: function (response) {
                $(`#searched_product_${data.productId}`).remove();
                $('#coming_products').prepend(response.html);
            },
            error: function () {
                notifyError();
            }
        });
    });

    $(document).on('click', '.remove_receipt_product', function (e) {

        e.preventDefault();

        let data = {
            productId: $(this).data('productId')
        };

        $.ajax({
            type: "POST",
            url: $(this).data('action'),
            data: data,
            dataType: 'json',
            success: function (response) {
                $(`#coming_product_${data.productId}`).remove();
            },
            error: function () {
                notifyError();
            }
        });
    });

    $(document).on('keyup change', '.purchase_price_input' , purchasePriceChange);

    $(document).on('keyup change', '.new_price_input' , validatePrice);

    $(document).on('keyup change', '.count_receipt' , validateCount);

    $('#margin_percent').on('keyup change', marginChange);

    function validatePrice() {
        let productId = $(this).data('productId');

        let newPrice = $(this).val();

        let currentPrice = $(`#price_${productId}`).data('value');

        if (+newPrice >= +currentPrice) {
            $(this).removeClass('border-danger text-danger').addClass('border-success text-success');
        } else {
            $(this).removeClass('border-success text-success').addClass('border-danger text-danger');
        }
    }

    function validateCount() {
        let count = $(this).val();

        if (count > 0) {
            $(this).removeClass('border-danger text-danger').addClass('border-success text-success');
        } else {
            $(this).removeClass('border-success text-success').addClass('border-danger text-danger');
        }
    }

    function purchasePriceChange() {

        let productId = $(this).data('productId');

        let purchasePrice = $(this).val();

        let margin = $('#margin_percent').val();

        let ratio = ((100 - margin) / 100).toFixed(2);

        let newPrice = (purchasePrice / ratio).toFixed(2);

        let decimal = newPrice % 1 === 0 ? 0 : 2;

        newPrice = (Math.round(newPrice * 2) / 2).toFixed(decimal);

        $(`#new_price_${productId}`).val(newPrice).trigger('change');
    }

    function marginChange() {

        if ($('#margin_percent').val() > 99) {
            $('#margin_percent').val(99)
        }

        $('.purchase_price_input').trigger('change');
    }

});
