$(document).ready(function () {

    $(document).on('change', '.checked_full_group', e => {
        e.preventDefault();

        const status = $(e.target).prop('checked')

        console.log(status)

        $(`.attribute_with_${$(e.target).val()}`).prop('checked', status)
    })


});
