/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


window.$ = window.jQuery = require('jquery');

require('jquery-serializejson')

require('jquery.easing')

require('bootstrap');

require('./template');

require('lightbox2');

//require("mdbootstrap/js/mdb.min");

//require("mdbootstrap/js/addons/datatables.min");

window.toastr = require("toastr/toastr");

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

window.notifySuccess = (message, title) => {
    toastr.success(message, title, {timeOut: 3000});
};

window.notifyWarning = (message, title) => {
    toastr.warning(message, title, {timeOut: 3000});
};

window.notifyError = (message = 'Что-то пошло не так :(', title) => {
    toastr.error(message, title, {timeOut: 3000});
};

require('./more-product-photo');

require('./product');

require('./category');

require('./toggle-enable');

require('./approve-delete');

require('./approve-archive');

require('./slider-route-models');

require('./smart-update-product');

require('./smart-update-attribute');

require('./stickers-controls');

require('./products-accouning');

window.Chart = require('chart.js/dist/chart');

window.number_format = (number, decimals, dec_point, thousands_sep) => {
    // *     example: number_format(1234.56, 2, ',', ' ');
    // *     return: '1 234,56'
    number = (number + '').replace(',', '').replace(' ', '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function(n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

window.bsCustomFileInput = require("bs-custom-file-input");

require('../vendor/tinymce.min');


/*
*
*
!!!!!! NEEDED FOR TUNYMCE IN SMART PRODUCT EDIT !!!!!
*
*
* */
window.initEditor = function (productId = null) {
    let className = productId ? '.textarea-editor-' + productId : '.textarea-editor';

    tinymce.remove(className);

    tinymce.init({
        selector: className,
        language: 'ru',
        plugins: [
            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars code fullscreen',
            'insertdatetime media nonbreaking save table directionality spellchecker configurepermanentpen',
            'emoticons template paste textcolor colorpicker textpattern image imagetools textcolor',
        ],
        toolbar: 'forecolor backcolor | insertfile undo redo | styleselect | fontsizeselect | bold italic emoticons | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media',
        contextmenu: false,
        relative_urls: false,
        height: 600,
        file_picker_callback (callback, value, meta) {
            let x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth
            let y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight

            tinymce.activeEditor.windowManager.openUrl({
                url : '/file-manager/tinymce5',
                title : 'Файловый менеджер',
                width : x * 0.8,
                height : y * 0.8,
                onMessage: (api, message) => {
                    callback(message.content, { text: message.text })
                }
            })
        },

    });
};

document.addEventListener('DOMContentLoaded', () => {
    window.initEditor();

    fm.$store.commit('fm/setFileCallBack', function (fileUrl) {
        let modal = $('#file_manager_modal');
        let modalTarget = modal.data('targetSelectedImage');

        $('#' + modalTarget).val(fileUrl);
        $('#selected_' + modalTarget).attr('src', fileUrl);

        modal.modal('hide');
        return false;
    });
});

let imageSelectedItems = document.querySelectorAll(".select_image");
for (let i = 0; i < imageSelectedItems.length; i++) {
    imageSelectedItems[i].addEventListener("click", function (event) {
        $('#file_manager_modal').data('targetSelectedImage', $(this).data('targetSelectedImage')).modal('show');
    }, false);
}
