$(document).ready(function () {
    $('#sticker_save').on('click', function (event) {
        event.preventDefault();

        let data = {
            text: $('#sticker_text').val(),
            color: $('#sticker_color').val(),
        };

        $.ajax({
            type: "POST",
            url: $('#form_add_sticker').data('action'),
            data: data,
            dataType: 'json',
            success: function (response) {
                $('#stickers_list').append(`
                <div class="checkbox row p-0 m-0" id="sticker_${response.model.id}">
                <div class="col-10 pl-0">
                <label class="w-100 h-100 m-0 d-flex align-items-center white-text" style="background: ${response.model.color}">
                <input class="mx-2" type="checkbox" name="stickers_ids[]" value="${response.model.id}" checked>${response.model.text}</label>
                </div>
                
                <div class="col-2 p-0">
                <button class="sticker_delete btn btn-sm btn-danger m-0" data-action="${response.remove_route}"><i class="fas fa-trash-alt"></i></button>
                </div>
                </div>
                `);

                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        });
    });

    $('.sticker_delete').on('click', function (event) {
        event.preventDefault();

        $.ajax({
            type: "POST",
            url: $(this).data('action'),
            dataType: 'json',
            success: function (response) {
                $(`#sticker_${response.remove_model_id}`).remove();

                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        });
    })
});