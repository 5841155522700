$(document).ready(function () {

    /* CATEGORIES */
    $('.product_category_checkbox').on('change', e => {
        e.preventDefault();

        let categoriesIds = []

        $.each($('.product_category_checkbox:checked'), (key, item) => {
            categoriesIds.push($(item).val())
        })

        $.ajax({
            type: "POST",
            url: $(e.target).data('loadAttributes'),
            data: {
                categories_ids: categoriesIds,
                model_id: $(e.target).data('modelId')
            },
            dataType: 'json',
            success: function (response) {
                console.log(response)
                $('#product_attributes_container').html(response.html)
            },
            error: function () {
                notifyError();
            }
        })
    })

    /* IMAGES */
    $('input[name="file_main"]').on('change', e => {
        e.preventDefault()

        console.log(1);

        $.ajax({
            type: "POST",
            url: $(e.target).data('action'),
            data: {
                id: $(e.target).val()
            },
            dataType: 'json',

            success: function (response) {
                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        })
    })

    $(document).on('change', '#field__select_type', e => {
        e.preventDefault();

        let enable = $(e.target).val() == 0

        $('#field__input_old_price').attr('disabled', !enable)

        if (enable) {
            $('#field__input_old_price').val(calcOldPrice($('#field__input_price').val()))
        } else {
            $('#field__input_old_price').val(null)
        }
    })

    $(document).on('change', '#field__input_price', e => {
        e.preventDefault();

        if (!$('#field__input_old_price').attr('disabled')) {
            $('#field__input_old_price').val(calcOldPrice($(e.target).val()))
        }
    })


});

function calcOldPrice(price) {

    let result = ((price / 0.55) * 100) / 100;

    return Math.round(result * 2) / 2
}
