$(document).ready(function () {

    $(document).on('change', '.attribute_group_select', function (e) {
        e.preventDefault();

        let select = $(this);

        let action = select.data('action');
        let name = select.data('name');
        let group = select.val();

        $.ajax({
            type: "POST",
            url: action,
            data:  {
                name: name,
                group_id: group,
                is_ajax: 1
            },
            dataType: 'json',
            beforeSend: function() {
                select.css({"opacity":"0.5"});
            },
            success: function (response) {
                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        }).always(() => {
            select.css({"opacity":"1"});
        });
    })

    $(document).on('change', '.attribute_type_select', function (e) {
        e.preventDefault();

        let select = $(this);

        let action = select.data('action');
        let name = select.data('name');
        let type = select.val();

        $.ajax({
            type: "POST",
            url: action,
            data:  {
                name: name,
                type: type,
                is_ajax: 1
            },
            dataType: 'json',
            beforeSend: function() {
                select.css({"opacity":"0.5"});
            },
            success: function (response) {
                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        }).always(() => {
            select.css({"opacity":"1"});
        });
    })

});
