$(document).ready(function () {

    $('#slide_route').on('change', function (event) {
        event.preventDefault();

        let select = $(this);
        let selectedRoute = select.val();

        if (selectedRoute === 'null') {
            $('#slide_route_models_container').addClass('d-none');
            $('#slide_url').addClass('d-none');
            $('#slide_route_models').val(0);
            $('.slide_route_id_option').remove();

            return false;
        }

        if (selectedRoute === 'url') {
            $('#slide_route_models_container').addClass('d-none');
            $('#slide_url').removeClass('d-none');
            $('#slide_route_models').val(0);
            $('.slide_route_id_option').remove();

            return false;
        }

        $('#slide_url').addClass('d-none');

        $('#slide_route_models_container').removeClass('d-none');

        $.ajax({
            type: "POST",
            data: {route: selectedRoute},
            url: select.data('action'),
            dataType: 'json',
            beforeSend: () => {
                $('#slide_route_models').attr('disabled', true);
            },
            success: function (response) {
                $('.slide_route_id_option').remove();

                $.each(response.route_models, (key, model) => {
                    $('#slide_route_models').append(
                        $('<option />')
                            .addClass('slide_route_id_option')
                            .val(model.id)
                            .text(model.title)
                    );
                });

                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        }).always(() => {
            $('#slide_route_models').attr('disabled', false);
        });
    });
});
